import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {UntypedFormControl, Validators, FormGroup, FormBuilder, FormControl} from '@angular/forms';
import {User} from '../../../services/api.client';
import {Agency} from '../../../services/api.client';
import {PledgeType} from '../../../services/api.client';
import {PledgeTypeService} from '../../../services/PledgeTypeService';
import {UserService} from '../../../services/UserService';
import Swal from 'sweetalert2';
// modals
import {AgencyListModalComponent} from '../agency/AgencyListModalComponent';
// enums
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store';
import {selectPledgeTypeDataPledgeTypesItems} from '../../../store/selectors/pledgeType.selectors';
import {selectSecurityStateDataCurrentUser} from '../../../store/selectors/security.selectors';

@Component({
    templateUrl: './UserEmailComponent.html',
    styleUrls: ['./UserEmailComponent.scss']
})
export class UserEmailComponent implements OnInit {

    public form: FormGroup = this.fb.group({
        fromMail: ['', Validators.required],
        agencyId: [null],
        fundTypes: [[]],
        includeOptOut: [false, Validators.required],
        subject: ['', Validators.required],
        content: ['', Validators.required],
        agencyName: [''] // not actually used in submission
    });

    constructor(
        private fb: FormBuilder,
        private _pledgeTypeService: PledgeTypeService,
        private _userService: UserService,
        private _store: Store<fromRoot.AppState>
    ) {
        this.loggedInUser$ = this._store.pipe(select(selectSecurityStateDataCurrentUser));
        this.allPledgeTypes$ = this._store.pipe(select(selectPledgeTypeDataPledgeTypesItems));
    }

    loggedInUser: User;
    loggedInUser$: Observable<User>;

    allPledgeTypes: PledgeType[];
    allPledgeTypes$: Observable<PledgeType[]>;

    public fromMail = this.form.get('fromMail') as FormControl;
    public agencyId = this.form.get('agencyId') as FormControl;
    public fundTypes = this.form.get('fundTypes') as FormControl;
    public includeOptOut = this.form.get('includeOptOut') as FormControl;
    public subject = this.form.get('subject') as FormControl;
    public content = this.form.get('content') as FormControl;
    public agencyName = this.form.get('agencyName') as FormControl;

    public selectAllFundTypes = new FormControl<boolean>(true);

    // -------- agency modal ------------
    selectedAgencyName = 'Choose an Agency';

    @ViewChild(AgencyListModalComponent)
    agencyListModal: AgencyListModalComponent;
    @Output('onShowAgencyModal') _showAgencies = new EventEmitter();
    private agencyList: AgencyListModalComponent;

    currentSelectedAgency: Agency;

    // -----------------------------------
    checkBoxDirty = false;
    selectedPledgeTypes: string[] = [];

    ngOnInit(): void {
        this.loggedInUser$.subscribe(user => {
            this.loggedInUser = user;
            this.form.get('fromMail').setValue(this.loggedInUser.emailAddress);
        });
        this.allPledgeTypes$.subscribe(allPledgeTypes => this.allPledgeTypes = allPledgeTypes);
        this.pledgeTypeLoad();
    }

    showAgencyModal(): boolean {
        this.agencyListModal.open();
        return false;
    }

    onAgencySelected(selectedAgency: Agency): void {
        if (selectedAgency) {
            this.currentSelectedAgency = selectedAgency;
            selectedAgency.agencyName ? this.selectedAgencyName = selectedAgency.agencyName : this.selectedAgencyName = '';
            this.form.get('agencyId').setValue(selectedAgency.id);
            this.form.get('agencyName').setValue(selectedAgency.agencyName);
        } else {
            this.currentSelectedAgency = selectedAgency;
            this.form.get('agencyId').setValue(null);
            this.form.get('agencyName').setValue('');
        }
    }

    pledgeTypesChange(pledgeType, checked: boolean): void {
        this.checkBoxDirty = true;
        if (checked) {
            this.selectedPledgeTypes.push(pledgeType.id);
            this.fundTypes.setValue(this.selectedPledgeTypes);
        } else {
            this.selectedPledgeTypes.splice(this.selectedPledgeTypes.findIndex(id => id === pledgeType.id), 1);
            this.fundTypes.setValue(this.selectedPledgeTypes);
        }
    }

    isChecked(pledgeType): boolean {
        if (this.currentSelectedAgency) {
            const types = this.currentSelectedAgency.pledgeTypeIds;
            if (types) {
                return types.indexOf(pledgeType.id) > -1;
            }
        }
    }

    pledgeTypeLoad(): void {
        this._pledgeTypeService.getPledgeTypes('', 'Name', false, 100, 0);
    }

    submit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        const agencyEmpty = !this.agencyId.value;
        const fundTypesEmpty = !this.fundTypes.value || this.fundTypes.value.length === 0;

        if (agencyEmpty && fundTypesEmpty) {
            Swal.fire({
                title: 'Are you sure you want to send this email to ALL users?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this._userService.sendMail(this.form.value)
                        .subscribe(() => {
                            this.form.reset();
                        });
                }
            });
        } else {
            this._userService.sendMail(this.form.value)
                .subscribe(() => {
                    this.form.reset();
                });
        }
    }
}
